import Navpath from "./components/navpath";
import "./assets/css/style.scss";

function App() {
  return (
    <div className="App">
      <div class="qodef-content-lines-holder qodef-skin--dark">
        <div class="qodef-content-line qodef--left"></div>
        <div class="qodef-content-line qodef--center"></div>
        <div class="qodef-content-line qodef--right"></div>
      </div>
      <Navpath />
    </div>
  );
}

export default App;
