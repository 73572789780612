import React from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { AiOutlineBarcode } from "react-icons/ai";
import { MdRestore } from "react-icons/md";
import { AiFillThunderbolt } from "react-icons/ai";
import ServiceModal from "./servicesModal";
import { BsCameraReelsFill, BsCameraVideo, BsCardImage, BsCcCircle, BsFileEarmark, BsFileEarmarkMusicFill, BsFillCameraVideoFill, BsFilm, BsInboxesFill, BsVinylFill } from "react-icons/bs";
import servicesbg from "../assets/images/servicesbg.png"

const Services = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [service, setService] = React.useState("");
  const [email, setEmail] = React.useState("");
  React.useEffect(() => {
    if (id) {
    console.log(id)
      setService(id);
    }
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");
   
    /* if the user scrolls down, the navbar will change to white */

    const handleScroll = () => {
      if (window.scrollY > 0) {
        nav.classList.add("bg-white");
        nav.classList.remove("bg-transparent");
        nav.classList.remove("text-white");
        navLink.forEach((item) => {
          item.style.color = "black";
          item.style.setProperty("color", "black", "important");
        });
      } else if (window.scrollY === 0) {
        nav.classList.add("bg-transparent");
        nav.classList.add("text-white");
        nav.classList.remove("bg-white");
        navLink.forEach((item) => {
          item.style.color = "white";
          item.style.setProperty("color", "white", "important");
        });
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

   React.useEffect(() => {
    console.log(service)
    if(service === "film-distribution"){
      setIsModalOpen(true);

      setText("AP International is the biggest and one of the oldest private studios (est. 1958) in Tamil Nadu & Kerala. It is a leading global company in the South Indian Film Entertainment Industry. Our key business activities include financing, acquisition and distribution of Tamil & Malayalam films in multiple formats such as theatrical, television and digital media.");
      setTitle("Film distribution");
      setEmail("distribution@apinternationalfilms.com");
    }else if(service === "conversion"){
      setIsModalOpen(true);
      setText("We offer advanced format conversion from DPX, TIFF, TGA and Image based formats to HD/2K/4K Apple Prores, XDCAM, H.264 and MPEG among others. Broadcast standard conversions with 8 Track Dolby E audio.");
      setTitle("Conversion");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "film-negative-scanning"){
      setIsModalOpen(true);
      setText("In this ‘digital’ era, film negatives are gradually becoming obsolete, due to the convenience of Digital Cinema. AP International is one of the key players in the market to offer the latest and most advanced film scanning services to digitise your 35mm negative and positive film into DPX Files with the highest ‘4K’ resolution.");
      setTitle("Film negative scanning");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "color-grading"){
      setIsModalOpen(true);
      setText("We appreciate the importance of colours in enhancing the interpretation of footage of a film to your audience. Beyond simple colour correction, we are pleased to offer a fully equipped 4K Colour Grading professional facility to take complete care of your colour critical projects and take their visual quality to a whole new level.");
      setTitle("Color grading");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "subtitling"){
      setIsModalOpen(true);
      setText("To ensure that your content reaches a global audience, we offer quality translation and subtitling services, for both your theatrical and post-release requirements. Our team of experts, with their fluency in the source and target languages, strive to capture the essence of the original content with ultimate precision.");
      setTitle("Subtitling");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "restoration"){
      setIsModalOpen(true);
      setText("Driven by the goal to revive compromised vintage films and also repair defects in newer films, we offer both automatic and manual restoration services in 4K resolution. In doing this, we ensure that along with preventing the deterioration of your video assets, we also maintain their intrinsic production quality.");
      setTitle("Restoration");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "archiving"){
      setIsModalOpen(true);
      setText("In order to protect your assets from theft, damage or quality deterioration, we offer conversion of your digital betacam tapes to digital video files for storage and future retrieval.");
      setTitle("Archiving");
      setEmail("sales@apinternationalfilms.com")
    }
    else if(service === "dvd-blu-ray-mastering"){
      setIsModalOpen(true);
      setText(`Our services offered: 

      Mpeg1 / VCD Mastering
      Mpeg2 / DVD Pre- Mastering / Authoring
      DVDR 5 / DVDR 9 Mastering
      H.264 Conversion/ Bluray Pre- Mastering / Authoring`);
      setTitle
      ("DVD, Blu-ray Mastering");
      setEmail("sales@apinternationalfilms.com")
    }
  }
  , [service]);

  return (
    <div>
      <Container fluid className="about-header" style={{ backgroundImage: `url(${servicesbg})` }}>
        <Row className="text-center justify-content-center about-header__row">
          <Col xs={12} md={4} className="py-5">
            <h2 className="display-4 text-white">Services</h2>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <Row className="text-center justify-content-center py-5">
          <Col xs={6} md={3}>
            <div
              className="icon-box"
              onClick={() => {
                setIsModalOpen(true);

                setText("AP International is the biggest and one of the oldest private studios (est. 1958) in Tamil Nadu & Kerala. It is a leading global company in the South Indian Film Entertainment Industry. Our key business activities include financing, acquisition and distribution of Tamil & Malayalam films in multiple formats such as theatrical, television and digital media.");
                setTitle("Film distribution");
                setEmail("distribution@apinternationalfilms.com");
              }}
              style={{ cursor: "pointer" }}
            >
              <BsFilm size={50} className="mb-3" />
              <h3>Film distribution</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div
              className="icon-box"
              onClick={() => {
                setIsModalOpen(true);
                setText("We offer advanced format conversion from DPX, TIFF, TGA and Image based formats to HD/2K/4K Apple Prores, XDCAM, H.264 and MPEG among others. Broadcast standard conversions with 8 Track Dolby E audio.");
                setTitle("Conversion");
                setEmail("sales@apinternationalfilms.com")
              }}
              style={{ cursor: "pointer" }}
            >
              <BsCameraVideo size={50} className="mb-3" />
              <h3>Conversion</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div
              className="icon-box"
              onClick={() => {
                setIsModalOpen(true);
                setText("In this ‘digital’ era, film negatives are gradually becoming obsolete, due to the convenience of Digital Cinema. AP International is one of the key players in the market to offer the latest and most advanced film scanning services to digitise your 35mm negative and positive film into DPX Files with the highest ‘4K’ resolution.");
                setTitle("Film negative scanning");
                setEmail("sales@apinternationalfilms.com")
              }}
              style={{ cursor: "pointer" }}
            >
              <BsCameraReelsFill size={50} className="mb-3" />
              <h3>Film negative scanning</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div
              className="icon-box"
              onClick={() => {
                setIsModalOpen(true);
                setText("We appreciate the importance of colours in enhancing the interpretation of footage of a film to your audience. Beyond simple colour correction, we are pleased to offer a fully equipped 4K Colour Grading professional facility to take complete care of your colour critical projects and take their visual quality to a whole new level.");
                setTitle("Color grading");
                setEmail("sales@apinternationalfilms.com")
              }}
              style={{ cursor: "pointer" }}
            >
              <BsCardImage size={50} className="mb-3" />
              <h3>Color grading</h3>
            </div>
          </Col>
        </Row>
        <Row className="text-center justify-content-center py-5">
          <Col xs={6} md={3}>
            <div className="icon-box"
            onClick={() => {
              setIsModalOpen(true);
              setText("To ensure that your content reaches a global audience, we offer quality translation and subtitling services, for both your theatrical and post-release requirements. Our team of experts, with their fluency in the source and target languages, strive to capture the essence of the original content with ultimate precision.");
              setTitle("Subtitling");
              setEmail("sales@apinternationalfilms.com")
            }}
            style={{ cursor: "pointer" }}
            >
              <BsCcCircle size={50} className="mb-3" />
              <h3>Subtitling</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="icon-box"
            onClick={() => {
              setIsModalOpen(true);
              setText("Driven by the goal to revive compromised vintage films and also repair defects in newer films, we offer both automatic and manual restoration services in 4K resolution. In doing this, we ensure that along with preventing the deterioration of your video assets, we also maintain their intrinsic production quality.");
              setTitle("Restoration");
              setEmail("sales@apinternationalfilms.com")
            }}
            style={{ cursor: "pointer" }}
            >
              <BsFileEarmarkMusicFill size={50} className="mb-3" />
              <h3>Restoration</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="icon-box"
            onClick={() => {
              setIsModalOpen(true);
              setText("In order to protect your assets from theft, damage or quality deterioration, we offer conversion of your digital betacam tapes to digital video files for storage and future retrieval.");
              setTitle("Archiving");
              setEmail("sales@apinternationalfilms.com")
            }}
            style={{ cursor: "pointer" }}
            >
              <BsInboxesFill size={50} className="mb-3" />
              <h3>Archiving</h3>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="icon-box"
            onClick={() => {
              setIsModalOpen(true);
              setText(`Our services offered: 

              Mpeg1 / VCD Mastering
              Mpeg2 / DVD Pre- Mastering / Authoring
              DVDR 5 / DVDR 9 Mastering
              H.264 Conversion/ Bluray Pre- Mastering / Authoring`);
              setTitle("DVD, Blu-ray Mastering");
              setEmail("sales@apinternationalfilms.com")
            }}
            style={{ cursor: "pointer" }}
            >
              <BsVinylFill size={50} className="mb-3" />
              <h3>DVD, Blu-ray Mastering</h3>
            </div>
          </Col>
        </Row>
      </Container>

      <ServiceModal
  key={service} // Add this line
  open={isModalOpen}
  onClose={() => setIsModalOpen(false)}
  text={text}
  title={title}
  email={email}
/>
    </div>
  );
};

export default Services;
