import React from "react";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "./images/1.jpg";
import Image2 from "./images/2.jpg";
import Image3 from "./images/3.jpg";
import Image4 from "./images/4.jpg";
import Image5 from "./images/5.jpg";
import {
  Button,
  Col,
  Container,
  Row,
  Tab,
  Table,
  Tabs,
  Carousel,
} from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiFillStar } from "react-icons/ai";
import Imdb from "./images/imdb.png";
import { BigPlayButton, ControlBar, Player } from "video-react";
import { getMoviedetails, getAllMovies } from "../../firebase";
import { useParams } from "react-router-dom";



const RelatedCarousel = () => {
  const { id } = useParams();
  const [movieData, setMovieData] = React.useState([]); // [
  const [playerUrl, setPlayerUrl] = React.useState("");
  const [allMovies, setAllMovies] = React.useState([]); // [
  const [reviews, setReviews] = React.useState([]); // [
  const [streaming, setStreaming] = React.useState([]); // [
  const [book, setBook] = React.useState([]); // [
  const isMobile = window.innerWidth <= 768;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show four slides at once
    slidesToScroll: 3, // Scroll four slides at a time
    autoplay: false,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Adjust this value based on your needs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024, // Adjust this value based on your needs
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 1440, // Adjust this value based on your needs
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      }
    ]
  };

  // Helper function to chunk the allMovies array into groups of four
  const chunkMovies = (movies, size) =>
    movies.reduce((chunks, item, index) => {
      const chunkIndex = Math.floor(index / size);
      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = []; // start a new chunk
      }
      chunks[chunkIndex].push(item);
      return chunks;
    }, []);

  const groupedMovies = chunkMovies(allMovies, 4);
  

  React.useEffect(() => {
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");
   
    /* if the user scrolls down, the navbar will change to white */

    const handleScroll = () => {
      if (window.scrollY > 0) {
        nav.classList.add("bg-white");
        nav.classList.remove("bg-transparent");
        nav.classList.remove("text-white");
        navLink.forEach((item) => {
          item.style.color = "black";
          item.style.setProperty("color", "black", "important");
        });
      } else if (window.scrollY === 0) {
        nav.classList.add("bg-transparent");
        nav.classList.add("text-white");
        nav.classList.remove("bg-white");
        navLink.forEach((item) => {
          item.style.color = "white";
          item.style.setProperty("color", "white", "important");
        });
      }
    }
    window.addEventListener("scroll", handleScroll);

    getMoviedetails(id).then((res) => {
      // console.log('movie details', res);
      let data = [];
      res.forEach((item) => {
        data.push(item.data());
      });
      setMovieData(data[0]);
      setPlayerUrl(data[0].videos[0]);
      setReviews(data[0].reviews || []);
    setStreaming(data[0].stream || []);
    setBook(data[0].book || []);
    });

    getAllMovies().then((res) => {
      // console.log('all movies', res);
      let data = [];
      res.forEach((item) => {
        data.push(item.data());
      });
      setAllMovies(data);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("movie data", movieData);

  

  return (
    <>
      <Container
        fluid
        style={{
          backgroundImage: `url(${movieData.banner})`,
          height: "100vh",
          marginTop: "-121px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          zIndex: "-1",
        }}
        className="movie-detail-banner p-0"
      >
        <div className="movie-detail-banner-overlay"></div>
        <Container className="movie-detail-banner-content">
          <Row className="movie-detail-banner-content-row">
            <Col xs={12} md={6}>
              <h2 className="big-title">{movieData.movie_title}</h2>
              <div className="movie-rating">
                <AiFillStar style={{ color: "rgb(245, 197, 24)" }} size={32} />
                <span>{movieData.rating_star}</span>
                <img src={Imdb} alt="imdb" />
                <span
                  style={{
                    backgroundColor: "rgba(30, 30, 30, 0.25)",
                    border: " 0.5px solid rgb(255, 255, 255)",
                    padding: "3px",
                    fontWeight: "500",
                  }}
                  className="text-white"
                >
                  {movieData.adult ? "U/A" : "U"}
                </span>
                <p>Trending</p>
              </div>
              <div className="movie-description mt-4">
                <p className="text-white">{movieData.movie_description}</p>
              </div>
              <div className="movie-misc text-white">
                <p>
                  <b>Starring:</b> {movieData.starring}
                </p>
                <p>
                  <b>Genres:</b> {movieData.genres}
                </p>
                <p>
                  <b>language:</b> {movieData.language}
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}></Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={12} className="mt-4">
            <div>
              <iframe
                width="100%"
                height="500"
                src={playerUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div
              style={{ width: "100%" }}
              className="related-carousel-container"
            >
              {isMobile ? (
                <Carousel>
                  {movieData?.videos?.map((item) => {
                    return (
                      <Carousel.Item>
                        <div
                          className="related-carousel-item"
                          onClick={() => setPlayerUrl(item)}
                        >
                          {/* <img
                            src={movieData.thumbnail}
                            alt="carousel"
                            className="related-carousel-item__img"
                          /> */}
                          <embed
                            type="video/webm"
                            src={item}
                            width="100%"
                            height="200px"
                          ></embed>
                          <div className="related-carousel-item__title">
                            {/* <h2>{movieData.movie_title}</h2> */}
                            {/* <Button variant="primary">View</Button> */}
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              ) : (
                <Slider {...settings}>
                  {movieData?.videos?.map((item) => {
                    return (
                      <div
                        className="related-carousel-item"
                        onClick={() => setPlayerUrl(item)}
                      >
                        {/* <img
                          src={movieData.thumbnail}
                          alt="carousel"
                          className="related-carousel-item__img"
                        /> */}
                        <embed
                          type="video/webm"
                          src={item}
                          className="related-carousel-item__img"
                        ></embed>
                        <div className="related-carousel-item__title">
                          {/* <h2>{movieData.movie_title}</h2> */}
                          {/* <Button variant="primary">View</Button> */}
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </Col>
        </Row>
        {(reviews.length > 0 || streaming.length > 0 || book.length > 0) && (
        <Row className="movie-detail-tabs mt-5">
          <Col xs={12} md={12}>
            <h2>Book, Stream and Reviews</h2>
          </Col>
          <Col xs={12} md={12}>
            <Tabs
              defaultActiveKey="Book"
              id="uncontrolled-tab-example"
              className="mb-3"
              style={{ fontWeight: "400" }}
            >
              <Tab eventKey="Book" title="Book">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Book source</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {book.map((item, index) => {
                      return (
                        <tr>
                          <td>{item?.source}</td>
                          <td>
                            <a href={item?.link}>
                              <Button variant="primary" className="header-cta">
                                Book Now
                              </Button>
                            </a>
                          </td>
                        </tr>
                      );
                    }
                    )}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="Stream" title="Stream">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Stream source</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {streaming.map((item, index) => {
                      return (
                        <tr>
                          <td>{item?.source}</td>
                          <td>
                            <a href={item?.link}>
                              <Button variant="primary" className="header-cta">
                                Watch Now
                              </Button>
                            </a>
                          </td>
                        </tr>
                      );
                    }
                    )}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="Reviews" title="Reviews">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Reviews</th>
                      <th>Ratings</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews.map((item, index) => {
                      return (
                        <tr>
                          <td>{item?.source}</td>
                          <td>
                            <div style={{ width: 50, height: 50 }}>
                              <CircularProgressbar
                                value={item?.value}
                                text={`${item?.text}`}
                                styles={buildStyles({
                                  textColor: "#e7004c",
                                  pathColor: "#e7004c",
                                  trailColor: "transparent",
                                  textSize: "25px",
                                })}
                              />{" "}
                            </div>
                          </td>
                          <td>
                            <a href={item?.link}>
                              <Button variant="primary" className="header-cta">
                                Read Now
                              </Button>
                            </a>
                          </td>
                        </tr>
                      );
                    }
                    )}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        )}
        <Row className="py-5">
          <Col xs={12} md={12}>
            <h2>Related Movies</h2>
          </Col>
          <Col>
            <div
              style={{ width: "100%" }}
              className="related-carousel-container"
            >
              {isMobile ? (
                <Carousel>
                  {allMovies?.map((item, index) => {
                    return (
                      <Carousel.Item>
                        <div
                          className="related-carousel-item"
                          onClick={() =>
                            (window.location.href = `/movie/${item.slug}`)
                          }
                          key={index}
                          style={{ height: "auto" }}
                        >
                          <img
                            src={item.thumbnail}
                            alt="carousel"
                            className="related-carousel-item__img"
                          />
                          <div className="related-carousel-item__title">
                            <h2>{item.movie_title}</h2>
                            <Button variant="primary">View</Button>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              ) : (
<Carousel indicators={false} interval={null} wrap={false}>
  {groupedMovies.map((group, idx) => (
    <Carousel.Item key={idx}>
      <div className="d-flex justify-content-around">
        {group.map((movie, index) => (
          <div
            key={index}
            className="movie-carousel-item"
            onClick={() => (window.location.href = `/movie/${movie.slug}`)}
          >
            <img src={movie.thumbnail} alt="carousel" className="movie-carousel-item__img" />
            {/* <div className="movie-carousel-item__title">
              <h2>{movie.movie_title}</h2>
              <Button variant="primary">View</Button>
            </div> */}
          </div>
        ))}
      </div>
    </Carousel.Item>
  ))}
</Carousel>

              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RelatedCarousel;
