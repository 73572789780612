import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Container,
  Button,
  Offcanvas,
  Dropdown,
} from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import WhiteLogo from "../../assets/images/logowhite.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.header-navbar');
      if (window.scrollY > 0) {
          header.style.backgroundColor = 'rgba(255, 255, 255, 0.96)';
      } else {
          header.style.backgroundColor = '';
      }
  };


  window.addEventListener('scroll', handleScroll);

  // Cleanup the event listener on component unmount
  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
  }
  , []);
  
  return (
    <Navbar expand="lg" className="header-navbar">
      <Container className="header-navbar__container d-flex justify-content-center">
        <Row className="w-100 header-navbar__row align-items-center">
          <Col xs={2} sm={2} md={12} lg={12} xl={12} className="d-hide m-show">
            <div className="slide-menu">
              <RxHamburgerMenu onClick={toggleMenu} size="32px" />
              <Offcanvas show={showMenu} onHide={() => setShowMenu(false)}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="flex-column">
                    <Nav.Link href="/" className="slide-menu__nav-link">
                      Home
                    </Nav.Link>
                    <Nav.Link href="/movies" className="slide-menu__nav-link">
                      Our Movies
                    </Nav.Link>
                    <Nav.Link href="/about" className="slide-menu__nav-link">
                      About Us
                    </Nav.Link>
                    <Nav.Link href="/contact" className="slide-menu__nav-link">
                      Contact
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </Col>
          <Col
            xs={10}
            sm={10}
            md={12}
            lg={12}
            xl={12}
            className="header-navbar__col"
          >
            <Nav className="header-navbar__nav justify-content-between align-items-center" style={{marginLeft: 50}}>
              <Nav.Link
                href="/about"
                className="header-navbar__nav-link m-hide"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="/movies"
                className="header-navbar__nav-link m-hide"
              >
                Our Movies
              </Nav.Link>
              <Navbar.Brand href="/" className="header-navbar__brand">
  {location.pathname === "/" ||
  location.pathname === "/movies" ||
  location.pathname === "/contact" ? (
    <img
      src={Logo}
      alt="logo"
      className="header-navbar__logo d-block mx-auto"
    />
  ) : (
    <img
      src={WhiteLogo}
      alt="logo"
      className="header-navbar__logo d-block mx-auto"
    />
  )}
</Navbar.Brand>
              <Dropdown as={Nav.Item} className="m-hide">
        <Dropdown.Toggle as={Nav.Link} className="header-navbar__nav-link">
            Services Offered
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item href="/services/film-distribution"
            >Film distribution</Dropdown.Item>
            <Dropdown.Item href="/services/conversion"
            >Conversion</Dropdown.Item>
            <Dropdown.Item href="/services/film-negative-scanning"
            >Film negative scanning</Dropdown.Item>
            <Dropdown.Item href="/services/color-grading"
            >Color grading</Dropdown.Item>
            <Dropdown.Item href="/services/subtitling"
            >Subtitling</Dropdown.Item>
            <Dropdown.Item href="/services/restoration"
            >Restoration</Dropdown.Item>
            <Dropdown.Item href="/services/archiving"
            >Archiving</Dropdown.Item>
            <Dropdown.Item href="/services/dvd-blu-ray-mastering"
            >DVD, Blu-ray Mastering</Dropdown.Item>
            {/* Add more services as needed */}
        </Dropdown.Menu>
    </Dropdown>
              <Nav.Link
                href="/contact"
                className="header-navbar__nav-link m-hide"
              >
                Get in Touch
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Header;
