import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

function ServiceModal({ open, onClose, text, title, email }) {
  console.log("text", text);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Modal
        show={open}
        onHide={onClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
           style={{color: "#e7004c", fontSize: "2.5rem"}}
          >{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className="about-content">
            <Container>
              <Row className="justify-content-center py-5 about-content__row">
                <Col xs={12} md={6}>
                  
                <div>
                    <p> {text}</p>
                  </div>
                  <p style={{ fontWeight: "bold" }}
                  >For more information:</p>
                    <div>
                      <h3
                      style={{ fontWeight: "bold" }}
                      >AP International</h3>
                      <p>No.42, Old.80, New Avadi Road, Kilpauk, Chennai, Tamil Nadu 600010 <br />
                         <b>Phone:</b> 044 4296 5900 <br />
                         <b>Email:</b>
                        <a href={`mailto:${email}`} style={{ color: "#e7004c" }}>
                          {email}
                        </a>
                      </p>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                  <h3 className="display-5"
                  style={{ fontSize: "2rem" }}
                  >Contact Us</h3>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="message">
                          {/* <Form.Label>Message</Form.Label> */}
                          <Form.Control
                            as="textarea"
                            rows={8}
                            placeholder="Enter your message"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="about-content__inner-row py-3">
                      <Col xs={12} md={6}>
                        <Form.Group controlId="firstName">
                          {/* <Form.Label>Name</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="Enter your first name"
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col md={6}>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </Form.Group>
                  </Col> */}
                      <Col xs={12} md={6}>
                        <Form.Group controlId="emailAddress">
                          {/* <Form.Label>Email Address</Form.Label> */}
                          <Form.Control
                            type="email"
                            placeholder="Enter your email address"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <Row>
                  <Col md={6}>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter your phone number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="emailAddress">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Form.Group>
                  </Col>
                </Row> */}

                    <Button
                      variant="primary"
                      type="submit"
                      className="submit-btn"
                      style={{ borderColor: "#e7004c",
                      width: "100%",
                    }}
                    >
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ServiceModal;
