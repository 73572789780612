import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const Contact = () => {

  useEffect(() => {
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");

    navLink.forEach((item) => {
      item.style.color = "black";
      item.style.setProperty("color", "black", "important");
    });

    nav.style.backgroundColor = "white";
  }

  , []);

  return (
    <div>
      <Container fluid>
        <Row className="text-center justify-content-center">
          <Col xs={12} md={12}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.268102796446!2d80.23088787579113!3d13.082187587243434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265d53ff15acb%3A0xa8ca184bf9b836f0!2sAp%20International!5e0!3m2!1sen!2sin!4v1708368255207!5m2!1sen!2sin"
              width="100%"
              height="350"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              title="map"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <Container fluid className="about-content">
        <Container className="py-5">
          <Row className="justify-content-center py-5 about-content__row text-end">
            <Col xs={12} md={6}>
              <h3 className="display-5">Say Hello!</h3>
              <p className="lead">
                Fill up this form to reach our enthusiastic
                team and hit submit.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="message">
                      {/* <Form.Label>Message</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        rows={8}
                        placeholder="Enter your message"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="about-content__inner-row py-3">
                  <Col xs={12} md={6}>
                    <Form.Group controlId="firstName">
                      {/* <Form.Label>Name</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </Form.Group>
                  </Col> */}
                  <Col xs={12} md={6}>
                    <Form.Group controlId="emailAddress">
                      {/* <Form.Label>Email Address</Form.Label> */}
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6}>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter your phone number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="emailAddress">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Form.Group>
                  </Col>
                </Row> */}

                <Button
                  variant="primary"
                  type="submit"
                  className="submit-btn"
                  style={{
                    width: "100%",
                    borderColor: "#e7004c",
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Contact;
