import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEAPI_key,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGESENDER,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

// Get Slider data
export const getSliderdata = async () => {
  const q = query(collection(db, "Slider"), where("featured", "==", 1));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Get Single Movie  Details
export const getMoviedetails = async (slug) => {
  const q = query(collection(db, "movie"), where("slug", "==", slug));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Get all Movies
export const getAllMovies = async () => {
  const q = query(collection(db, "movie"));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Get all announcements
export const getAllAnnouncements = async () => {
  const q = query(collection(db, "announcements"));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Get Single Announcement Details
export const getAnnouncementdetails = async (slug) => {
  const q = query(collection(db, "announcements"), where("slug", "==", slug));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Get movies by genre
export const getMoviesbyGenre = async (genre) => {
  const q = query(collection(db, "movie"), where("genres", "==", genre));
  const docs = await getDocs(q);
  if (docs.docs.length > 0) {
    return docs.docs;
  } else {
    return [];
  }
};

// Store user data in firebase
export const contactFormData = async (data) => {
  const docRef = await addDoc(collection(db, "contact-form-data"), data);
  return docRef;
};

export const fetchMovies = async () => {
  const moviesCollection = collection(db, "movie");
  const moviesSnapshot = await getDocs(moviesCollection);
  return moviesSnapshot.docs.map(doc => doc.data());
}

//add data to the contact-form-data collection
export const addContactFormData = async (data) => {
  const docRef = await addDoc(collection(db, "contact-form-data"), data);
  return docRef;
};