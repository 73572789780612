import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BigPlayButton, ControlBar, Player } from "video-react";
import { Button, Carousel } from "react-bootstrap";
import { getSliderdata } from "../../firebase";

const VideoCarouselCustom = () => {
  const [movies, setMovies] = useState([]);
  const videoRef = useRef(null);
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
  };

  const convertToWatchLink = (embedLink) => {
    return embedLink.replace(
      "https://www.youtube.com/embed/",
      "https://www.youtube.com/watch?v="
    );
  };

  const playInFullscreen = (event, movie_trailer) => {
    event.stopPropagation();
    
    // Convert the embed link to a watch link
    const youtubeLink = convertToWatchLink(movie_trailer);
  
    // Open YouTube link in a new tab
    window.open(youtubeLink, "_blank");
  };
  



  useEffect(() => {
    const fetchMovies = async () => {
      const fetchedMovies = await getSliderdata();
      setMovies(
        fetchedMovies.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    };

    fetchMovies();
  }, []);

  if (isMobile) {
    return (
      <div style={{ width: "100%" }} className="video-carousel-custom-container">
        <Carousel>
          {movies.map((movie) => (
            <Carousel.Item key={movie.slug} className="video-carousel-custom-item-mobile">
              <div
                className="overlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 10,
                  cursor: "pointer",
                }}
                onClick={(event) => playInFullscreen(event, movie.movie_trailer)}
              ></div>
              <Player
                ref={videoRef}
                playsInline={false}
                poster={movie.thumbnail}
                src={movie.movie_trailer}
                className="video-carousel-custom-item-mobile__img"
              >
                <BigPlayButton position="center" />
                <ControlBar disableCompletely={true} />
              </Player>

              {/* <Button className="video-carousel-custom-item-mobile__button">
                {/* SVG icon here 
              </Button>
              <div className="video-carousel-custom-item-mobile__title">
                <h2>{movie.movie_title}</h2>
              </div> */}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }} className="video-carousel-custom-container">
      <Slider {...settings}>
        {movies.map((movie) => (
          <div key={movie.slug} className="video-carousel-custom-item">
            <div
              className="overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 10,
                cursor: "pointer",
              }}
              onClick={(event) => playInFullscreen(event, movie.movie_trailer)}
            ></div>
            <Player
              ref={videoRef}
              playsInline={false}
              poster={movie.thumbnail}
              src={movie.movie_trailer}
              className="video-carousel-custom-item__img"
            >
              <BigPlayButton position="center" />
              <ControlBar disableCompletely={true} />
            </Player>

            <Button className="video-carousel-custom-item__button">
              {/* SVG icon here */}
            </Button>
            <div className="video-carousel-custom-item__title">
              <h2>{movie.movie_title}</h2>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoCarouselCustom;
