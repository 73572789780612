import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Header from "./header";
import Footer from "./footer";
import Movie from "../pages/movie/";
import About from "../pages/about";
import Contact from "../pages/contactNew";
import Movies from "../pages/movies";
import Services from "../pages/services";

export default function Navpath() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/movie/:slug" element={<MovieDetail />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/movie/:id" element={<Movie />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:id" element={<Services />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
