import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { getAllMovies } from "../firebase/index";
import 'font-awesome/css/font-awesome.min.css';


// Modify this style to control the height of the card image
const cardImageStyle = {
  height: "25rem",
  objectFit: "cover",
};

// Modify this style for the overlay
const overlayStyle = {
  background:
    "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 100%)",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "1rem",
  color: "white",
};

// Add this style for the title and text
const textStyle = {
  textShadow: "2px 2px 4px #000",
  color: "#e7004c",
};

const Movies = () => {
  const [originalData, setOriginalData] = useState([]);
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (searchText === "") {
      setMovies(originalData);
    } else {
      let filteredMovies = originalData.filter((movie) =>
        movie.movie_title.toLowerCase().includes(searchText.toLowerCase())
      );
      setMovies(filteredMovies);
    }
  }, [searchText]);

  useEffect(() => {
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");

    navLink.forEach((item) => {
      item.style.color = "black";
      item.style.setProperty("color", "black", "important");
    });
    
    const fetchMovies = async () => {
      const moviesData = await getAllMovies();
      const formattedMovies = moviesData.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMovies(formattedMovies);
      setOriginalData(formattedMovies);
      setLoading(false);
    };

    fetchMovies();
  }, []);

  return (
    <div className="m-5">
      <Row className="my-5">
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={4}></Col>
        <Col xs={12} md={4}>
        <div style={{ position: 'relative', width: '300px' }}>
    <input
        type="text"
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        style={{
            width: '100%',
            padding: '10px 40px 10px 15px',
            borderRadius: '15px',
            border: '1px solid #ccc'
        }}
    />
    <i 
        className="fa fa-search" 
        style={{ 
            position: 'absolute', 
            right: '10px', 
            top: '50%', 
            transform: 'translateY(-50%)',
            pointerEvents: 'none' 
        }}
    ></i>
</div>
        </Col>
      </Row>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Row xs={1} md={2} lg={3} xl={4} xxl={6} className="g-4">
          {movies.map((movie) => (
            <Col key={movie.id}>
              <Card
                className={`h-100 rounded overflow-hidden position-relative card-hover-effect`}
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = `/movie/${movie.slug}`)}
              >
                <Card.Img
                  style={cardImageStyle}
                  src={movie.thumbnail}
                  alt={movie.movie_title}
                />
                <div style={overlayStyle}>
                  <Card.Title style={textStyle} className="text-white">
                    {movie.movie_title}
                  </Card.Title>
                  <Card.Text style={textStyle}>{movie.language}</Card.Text>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Movies;
