import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo_footer.jpg";
import QRCode from "../../assets/images/channelqr.png";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineWhatsApp, // Import the WhatsApp icon
} from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";

export default function Footer() {

  const whatsappUrl = "https://whatsapp.com/channel/0029VaBfefFK0IBoipYEGM1c";

  return (
    <>
      <Container fluid className="footer p-0">
        <Container className="footer__container">
          <Row className="footer__container__row-1 align-items-center">
          <Col xs={12} md={4} lg={3} className="footer__container__row__col-1">
              <img src={Logo} alt="logo" />
              <div className="footer__social">
                <AiFillFacebook
                  className="footer__social__icon"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/APInternational/"
                    )
                  }
                />
                <BsTwitterX
                  className="footer__social__icon"
                  onClick={() =>
                    window.open("https://twitter.com/apifilms?lang=en")
                  }
                />
                <AiFillInstagram
                  className="footer__social__icon"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/apinternationalfilms/"
                    )
                  }
                />
                <AiFillYoutube
                  className="footer__social__icon"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UChtEvBpe2GQkVzzxvMLLUHA"
                    )
                  }
                />
              </div>
            </Col>
            <Col xs={12} md={4} lg={6} className="footer__container__row__col-2">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/movies">Movies</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </Col>
            {/* WhatsApp QR Code and Link Column */}
            <Col xs={12} md={4} lg={3} className="footer__container__row__col-whatsapp text-lg-right">
              <div className="footer__qr-container">
                <img src={QRCode} alt="WhatsApp QR Code" className="footer__qr" />
                <div className="d-inline-block">
                  <AiOutlineWhatsApp
                    className="footer__whatsapp__icon"
                    onClick={() => window.open(whatsappUrl)}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="footer__whatsapp__text d-inline-block"
                  onClick={() => window.open(whatsappUrl)}
                  style={{ cursor: "pointer" }}
                  >Join our WhatsApp Channel</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="footer__container__row-2"></Row>
        </Container>
        <Container fluid className="footer__container-2">
          <Row className="footer__container-2__row-1 text-center">
            <Col xs={12} md={12}>
              <p className="text-white">© 2024 All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
