import React, { useEffect, useState } from "react";
import Carousel from "../components/carousel";
import { Button, Col, Container, Row } from "react-bootstrap";
import VideoCarouselCustom from "../components/videocarousel";
import Section3Img from "../assets/images/section3.png";
import Section3Layer1 from "../assets/images/layer1.png";
import Section3Layer2 from "../assets/images/layer2.jpg";
import Camera from "../assets/images/camera.png";
import Glass from "../assets/images/glass.png";
import Countdown from "../assets/images/countdown.png";
import Mask from "../assets/images/mask.png";
import Title5 from "../assets/images/title5.png";
import Blog1 from "../assets/images/blog1.jpg";
import Counter from "../components/counter";
import { youtubeSubsFn } from "youtubesubs-count-v1";
import { Link, Navigate } from "react-router-dom";
import { BsArrowBarRight, BsArrowRight } from "react-icons/bs";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { InstagramEmbed, FacebookEmbed } from "react-social-media-embed";
import { AiOutlineUsergroupAdd, AiOutlineEye, AiOutlineVideoCamera, AiFillYoutube } from "react-icons/ai";
import logo from "../assets/images/logo.png";
import YoutubeLogo from "../assets/images/Youtube-logo.svg";
import coverImage from "../assets/images/cover.jpg";
import logo1 from "../assets/images/logo1.jpg";

export default function Home() {
  const [subscriberCount, setSubscriberCount] = React.useState(0);
  const [viewCount, setViewCount] = React.useState(0);
  const [videoCount, setVideoCount] = React.useState(0);
  // youtubeSubsFn(
  //   "UChtEvBpe2GQkVzzxvMLLUHA",
  //   "AIzaSyBmYL8_U9X1hgSqmMbm6yhJ36CiV6_SBMs"
  // )
  //   .then((data) => {
  //     // console.log(data)
  //     //       statistics
  //     // :
  //     // hiddenSubscriberCount
  //     // :
  //     // false
  //     // subscriberCount
  //     // :
  //     // "9090000"
  //     // videoCount
  //     // :
  //     // "24693"
  //     // viewCount
  //     // :
  //     // "6542921757"

  //     setSubscriberCount(data.statistics.subscriberCount);
  //     setViewCount(data.statistics.viewCount);
  //     setVideoCount(data.statistics.videoCount);
  //   })
  //   .catch((error) => {
  //     // console.log(error)
  //   });

  useEffect(() => {
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");

    navLink.forEach((item) => {
      item.style.color = "black";
      item.style.setProperty("color", "black", "important");
    });
    const fetchSubscriberCount = () => {
      fetch("https://mixerno.space/api/youtube-channel-counter/user/UChtEvBpe2GQkVzzxvMLLUHA", {
        method: 'GET',
        headers: {
          'Accept-Encoding': 'gzip, deflate, br',
          'Accept-Language': 'en-US,en;q=0.9',
          'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
          'Referer': 'https://livecounts.io/',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const subscribers = data.counts.find(count => count.value === "subscribers").count;
          const views = data.counts.find(count => count.value === "views").count;
          const videos = data.counts.find(count => count.value === "videos").count;

          setSubscriberCount(subscribers);
          setViewCount(views);
          setVideoCount(videos);
        })
        .catch((error) => console.error('Error fetching subscriber count:', error));
    };

    fetchSubscriberCount();
    const intervalId = setInterval(fetchSubscriberCount, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);


  return (
    <div>
      <Container fluid className="home-container mt-5">
        <Row className="home-row">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="home-col d-flex justify-content-center align-items-center p-0"
          >
            <Carousel />
          </Col>
        </Row>
        <Container className="section-2-container">
          <Row className="section-2-row text-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="section-2-col"
            >
              <p>This month</p>
              <h1>Trending Trailers</h1>
            </Col>
          </Row>
        </Container>
        <VideoCarouselCustom />
        <Container className="section-3-container">
          <Row className="section-3-row">
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="section-3-col-1 p-5"
            >
              <img
                src={Section3Img}
                alt="section3"
                className="mx-auto d-block"
                style={{ width: "80%", height: "auto" }}
              />
              <img src={Section3Layer1} alt="layer1" className="layer1" />
              <img src={Section3Layer2} alt="layer2" className="layer2" />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="section-3-col-2  d-flex flex-column justify-content-center align-items-start"
            >
              <p>“AP International: Evolving Film Frontiers”</p>
              <h2>Charting new horizons in South Indian film industry.</h2>
              <Button
                variant="primary"
                className="buy-tickets-btn"
                href="/about"
              >
                Know More
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="section-4-container">
          <Row className="section-4-row text-center" gutter={16}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="section-4-col"
            >
              <h2 className="text-center">What We Offer</h2>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Camera} alt="camera" />
                <h3>Film negative scanning</h3>
                <p>
                  Most advanced film scanning services to digitise negative and
                  positive films.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Glass} alt="camera" />
                <h3>Color grading</h3>
                <p>
                  Take the visual quality to a whole new level for color
                  critical projects.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Countdown} alt="camera" />
                <h3>Film distribution</h3>
                <p>
                  Distribution of Tamil & Malayalam films in theatrical,
                  television and digital media.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Mask} alt="camera" />
                <h3>Restoration</h3>
                <p>
                  Automatic and manual restoration services in 4K resolution
                </p>
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-4">
            <Col xs={12}>
              <Link to="/services">
                <BsArrowRight size={30} color="#000" />
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* <Container fluid className="section-5-container m-0">
        <Container
          className="section-5-container-inner"
          style={{ height: "100%" }}
        >
          <Row className="section-5-row" style={{ height: "100%" }}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="section-5-col"
            >
              <img src={Title5} alt="title5" className="title5" />
              <Button variant="primary" className="buy-tickets-btn">
                Get Tickets
              </Button>
            </Col>
          </Row>
        </Container>
      </Container> */}
      {/* <Container className="section-6-container">
        <Row className="section-6-row">
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="section-6-col-1">
            <p>“ONE MORE DAY TILL I FADE”</p>
            <h2>
              “DISCOVERING ALL THE OLD NARRATIVES IN{" "}
              <span className="strike">MODERN</span> <span>FILM</span> ”
            </h2>
            <Button variant="primary" className="buy-tickets-btn">
              Buy Tickets
            </Button>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="section-6-col-2">
            <video width="100%" height="auto" controls>
              <source src="https://vimeo.com/90049558" type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container> */}
      <Container className="section-counter" style={{ backgroundColor: 'white' }}>
        <Row className="text-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="title-with-logo">
              <img src={YoutubeLogo} alt="youtube-logo" style={{ width: '200px', height: '40px' }} />
            </div>
            <div className="channel-banner">
        <img src={coverImage} alt="Cover" className="cover-image" />
        <img src={logo1} alt="Channel Logo" className="channel-logo" />
      </div>
          </Col>
        </Row>
        <Row className="section-counter-row text-center">
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="section-counter-col">
            <div className="counter-box">
              <h3 style={{ fontSize: '4rem' }}>{subscriberCount}</h3>
              <p><AiOutlineUsergroupAdd /> SUBSCRIBERS</p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="section-counter-col">
            <div className="counter-box">
            <h3 style={{ fontSize: '4rem' }}>{viewCount}</h3>
              <p><AiOutlineEye /> VIEWS</p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="section-counter-col">
            <div className="counter-box">
              <h3 style={{ fontSize: '4rem' }}>{videoCount}</h3>
              <p><AiOutlineVideoCamera /> VIDEOS</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginBottom: "225px", marginTop: "425px" }}>
          <h1 style={{ textAlign: "center", width: "100%" }}
          >Our Social Community</h1>
        </Row>
      </Container>
      <Container fluid style={{ background: "#EBEBEB", height: "300px" }}>
        <Container className="section-7-container">
          <Row className="section-7-row">
            <Col xs={12} sm={12} md={4} lg={4} xl={4} className="section-7-col">
              <div className="section-7-card">
                <FacebookEmbed
                  url="https://www.facebook.com/APInternational"
                />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="section-7-col"
            >
              <div className="section-7-card">
                <InstagramEmbed url="https://www.instagram.com/apinternationalfilms" />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="section-7-col"
            >
              <div className="section-7-card">
                <TwitterTimelineEmbed
                  onLoad={function noRefCheck() { }}
                  options={{
                    height: 400
                  }}
                  screenName="APIfilms"
                  sourceType="profile"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
