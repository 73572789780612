import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row, Modal } from "react-bootstrap";
import Camera from "../assets/images/camera.png";
import Glass from "../assets/images/glass.png";
import Countdown from "../assets/images/countdown.png";
import Mask from "../assets/images/mask.png";
import About2 from "../assets/images/about2.png";
import Pic2 from "../assets/images/2.jpg";
import HorizontalTimeline from "react-horizontal-timeline";
import Audio from "../assets/images/timeline/Audio-CD.jpg";
import DVD from "../assets/images/timeline/API-DVD.jpg";
import Viu from "../assets/images/timeline/Viu.jpg";
import ChandraMukhi from "../assets/images/timeline/API-Chandramukhi.png";
import InFlightEntertainment from "../assets/images/timeline/API-In-flight-Entertainment.png";
import Mannan from "../assets/images/timeline/API-Mannan.jpg";
import Vikram from "../assets/images/timeline/API-Vikram.jpg";
import Youtube from "../assets/images/timeline/API-Youtube.png";
import Golden from "../assets/images/timeline/Golden.png";
import Inception from "../assets/images/timeline/inception.jpg"
import Bhramam from "../assets/images/timeline/bhramam.jpg";
import RelianceEntertainment from "../assets/images/timeline/Reliance-Entertainment.jpg";
import { addContactFormData } from "../firebase/index";

const About = () => {
  const [value, setValue] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  // Values should be only date
  const VALUES = ["1958", "1987", "1992", "2000", "2002", "2003", "2005", "2011", "2017", "2019", "2021", "2022", "2023"];

  const handleTimeline = (index) => {
    const tl1 = document.getElementById("tl1");
    const tl2 = document.getElementById("tl2");

    if (index === 0) {
      tl1.classList.add("active");
      tl2.classList.remove("active");
    } else if (index === 1) {
      tl1.classList.remove("active");
      tl2.classList.add("active");
    }
  };

  React.useEffect(() => {
    const nav = document.querySelector("nav");
    const navLink = document.querySelectorAll(".nav-link");
   
    /* if the user scrolls down, the navbar will change to white */

    const handleScroll = () => {
      if (window.scrollY > 0) {
        nav.classList.add("bg-white");
        nav.classList.remove("bg-transparent");
        nav.classList.remove("text-white");
        navLink.forEach((item) => {
          item.style.color = "black";
          item.style.setProperty("color", "black", "important");
        });
      } else if (window.scrollY === 0) {
        nav.classList.add("bg-transparent");
        nav.classList.add("text-white");
        nav.classList.remove("bg-white");
        navLink.forEach((item) => {
          item.style.color = "white";
          item.style.setProperty("color", "white", "important");
        });
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      message: e.target.message.value,
      fname: e.target.firstName.value,
      email: e.target.emailAddress.value,
      lname: "",
      phone: ""
    }
    addContactFormData(data);
  }

  return (
    <div>
      <Container fluid className="about-header">
        <Row className="text-center justify-content-center about-header__row">
          <Col xs={12} md={4} className="py-5">
            <h2 className="display-4 text-white">About Us</h2>
          </Col>
        </Row>
      </Container>
      <Container fluid className="about-content">
        <Container className="section-4-container">
          <Row className="section-4-row text-center" gutter={16}>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Camera} alt="camera" />
                <h3>Bridging Films & Audiences</h3>
                <p>Our commitment to connecting storytellers and viewers.</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Glass} alt="camera" />
                <h3>Masters of Distribution</h3>
                <p>How we've refined our craft over the years.</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Countdown} alt="camera" />
                <h3>The Distributor's Difference</h3>
                <p>What sets us apart in the industry.</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} className="section-4-col">
              <div className="section-4-box">
                <img src={Mask} alt="camera" />
                <h3>Collaboration at its Core</h3>
                <p>The filmmakers, theaters, and platforms we work with.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="about-content-4 p-0">
        <Container className="p-0">
          <Row className="justify-content-center p-0 about-content__row">
            <Col xs={12} md={6} className="py-5 about-content__col text-end">
              <span className="display-6">AP International </span>
              <h2 className="display-5">
                Pioneering South Indian Film Distribution in the Digital Age
              </h2>
              <p className="lead">
              Established in 1958, AP International emerged as a trailblazer in the realm of South Indian film entertainment.
              </p>
              <Button
                variant="primary"
                className="mt-3"
                style={{
                  backgroundColor: "#e7004c",
                  border: "none",
                  height: "50px",
                  padding: "0 20px",
                }}
                onClick={() => setModalShow(true)}
              >
                Read More
              </Button>
            </Col>
            <Col xs={12} md={6} className="about-content__col p-0">
              <Image src={About2} alt="about" fluid className="about-image" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row className="about-content__row">
          <Col xs={12} md={12} className="about-content__col">
            <div
              className="root-div"
              style={{
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: "100px",
                  margin: "0 auto",
                }}
              >
                <HorizontalTimeline
                  styles={{ outline: "#000", foreground: "#000" }}
                  index={value}
                  indexClick={(index) => {
                    setValue(index);
                    setPrevious(value);
                    handleTimeline(index);
                  }}
                  values={VALUES}
                  getLabel={(date) => date}
                />
              </div>
              {/* {value === 0 && ( */}
              <div className="timeline-container">
                {/* Timeline for 1958 */}
                {value === 0 && (
                  <Row id="tl0" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Inception} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">1958 - The Inception</h2>
                      <p className="timeline-text">
                        The AP International Group was established. We’ve financed over 1000 films;
                        acquired copyrights of over 1500+ movies and over 500+ movie audio tracks; and
                        distributed over 1000+ films globally since. The oldest film from our digital rights catalogue
                        is Sabapathi from 1941.
                      </p>
                    </Col>
                  </Row>
                )}
                  {/* Timeline for 1987 */}
                {value === 1 && (
                  <Row id="tl1" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={About2} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">1987 - Theatre Release</h2>
                      <p className="timeline-text">
                        Satyamev Jayate, which we distributed overseas, was the first film we acquired 
                        theatrical rights for.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 1992 */}
                {value === 2 && (
                  <Row id="tl2" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Mannan} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">1992 - Mannan</h2>
                      <p className="timeline-text">
                      Superstar Rajinikanth starrer, Mannan, which we distributed overseas, was the first 
                      film to complete a 100-day run in London.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2000 */}
                {value === 3 && (
                  <Row id="tl3" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Audio} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2000 - Stepping into Distribution of Audio CDs</h2>
                      <p className="timeline-text">
                      We stepped into the distribution of Audio CDs for the first time for the Thalapathy 
Vijay starrer, Kushi. This was then followed by blockbusters like Chandramukhi, 
Aalavandhan, Sachein, Thotti Jaya, Virumaandi and Maayavi. 
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2002 */}
                {value === 4 && (
                  <Row id="tl4" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={DVD} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2002 - Entering the DVD Market</h2>
                      <p className="timeline-text">
                      We set up a studio for DVD authoring which has now transformed into a full-fledged 
studio that delivers content to 10+ platforms worldwide. This includes Netflix, Amazon Prime 
Video, Media Corp, Google, Astro Malaysia, Etisalat, and Mauritius Telecorp, among others. 
We are also specialists in scanning of negatives in 4K and the restoration of several 
blockbusters.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2003 */}
                {value === 5 && (
                  <Row id="tl5" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={InFlightEntertainment} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2003 - In-flight entertainment</h2>
                      <p className="timeline-text">
                      We initiated in-flight entertainment, for a wide range of movies, on domestic as well 
as international flights.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2005 */}
                {value === 6 && (
                  <Row id="tl6" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={ChandraMukhi} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2005 - Chandramukhi</h2>
                      <p className="timeline-text">
                      Chandramukhi, the Superstar Rajinikanth starrer, which we distributed overseas, 
continues to be the first and only film that completed a theatrical run of 890 days in India
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2011 */}
                {value === 7 && (
                  <Row id="tl7" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Golden} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2011 - Co-founded Golden Cinema LLC</h2>
                      <p className="timeline-text">
                      Co-founded Golden Cinema LLC, UAE along with Ayngaran International, UK.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2017 */}
                {value === 8 && (
                  <Row id="tl8" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={RelianceEntertainment} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2017 - Reliance Entertainment & YNOT Studios</h2>
                      <p className="timeline-text">
                      Joined hands with Reliance Entertainment & Y Not Studios in movie production. 
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2019 */}
                {value === 9 && (
                  <Row id="tl9" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Viu} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2019 - Web-series for Viu</h2>
                      <p className="timeline-text">
                      Co-produced web series for Viu
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2021 */}
                {value === 10 && (
                  <Row id="tl10" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Bhramam} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2021 - Stepping into Production</h2>
                      <p className="timeline-text">
                      Produced our first film independently - Bhramam (Malayalam) starring Prithviraj, 
which was released directly on the digital platform, Amazon Prime Video in India.  
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2022 */}
                {value === 11 && (
                  <Row id="tl11" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Vikram} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2022 - Vikram</h2>
                      <p className="timeline-text">
                      The trailer of the Ulaganayagan Kamal Hassan starrer, Vikram, that we distributed 
worldwide, was screened at the Burj Khalifa in Dubai.
                      </p>
                    </Col>
                  </Row>
                )}

                {/* Timeline for 2023 */}
                {value === 12 && (
                  <Row id="tl12" className="timeline-row active">
                    <Col xs={12} md={6} className="timeline-col">
                      <Image src={Youtube} width="100%" />
                    </Col>
                    <Col xs={12} md={6} className="timeline-col-2">
                      <h2 className="timeline-title">2023 - Viewership</h2>
                      <p className="timeline-text">
                      As of today, the collective subscriber count for our YouTube channels stands at 
20M+
                      </p>
                    </Col>
                  </Row>
                )}


              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container className="py-5">
        <Row className="about-team-container justify-content-center"> */}
          {/* <Col xs={12} md={4} className="about-team-item">
            <img src={Pic2} className="about-team-item__img" />
            <Col className="about-team-item__title">
              <h2>Our Team</h2>
              <p>CFO</p>
            </Col>
          </Col> */}
          {/* <Col xs={12} md={4} className="about-team-item">
            <img src={Pic2} className="about-team-item__img" />
            <Col className="about-team-item__title">
              <h2>Our Team</h2>
              <p>CEO</p>
            </Col>
          </Col> */}
          {/* <Col xs={12} md={4} className="about-team-item">
            <img src={Pic2} className="about-team-item__img" />
            <Col className="about-team-item__title">
              <h2>Our Team</h2>
              <p>CTO</p>
            </Col>
          </Col> */}
        {/* </Row>
      </Container> */}
      <Container fluid className="about-content">
        <Container className="py-5 mt-5">
          <Row className="justify-content-center py-5 about-content__row text-end">
            <Col xs={12} md={6} className="about-content__col-2">
              <p>Say Hello</p>
              <p className="lead">
                Fill up this form to reach our enthusiastic team and click on send
                message.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="message">
                      {/* <Form.Label>Message</Form.Label> */}
                      <Form.Control
                        as="textarea"
                        rows={12}
                        placeholder="Enter your message"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="about-content__inner-row py-3">
                  <Col xs={12} md={6}>
                    <Form.Group controlId="firstName">
                      {/* <Form.Label>Name</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                    <Form.Group controlId="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </Form.Group>
                  </Col> */}
                  <Col xs={12} md={6}>
                    <Form.Group controlId="emailAddress">
                      {/* <Form.Label>Email Address</Form.Label> */}
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6}>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter your phone number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="emailAddress">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Form.Group>
                  </Col>
                </Row> */}

                <button
                  type="submit"
                  className="mt-3 submit-btn"
                  style={{
                    // backgroundColor: "#e7004c",
                    width: "100%",
                    // border: "none",
                    height: "60px",
                  }}
                >
                  Submit
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Established in 1958, AP International emerged as a trailblazer in the realm of South Indian film entertainment.
With its humble origins in music releases, AP International rapidly expanded its horizons, focusing on the
production and distribution of home entertainment products such as Blu-ray, DVD, VCD, and Audio CDs. The
company continued to swiftly advance, showcasing remarkable progress through the acquisition, coproduction,
and distribution of Tamil and Malayalam films across theatrical, television, and various other formats. AP
International has also established its place in the digital realm, bringing its extensive software library to
audiences through platforms like Satellite, Airborne, IPTV, Internet, DTH, VOD, PayTV, and more.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default About;
