import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchMovies } from "../../firebase/index";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";

const CarouselCustom = () => {
  const [movies, setMovies] = useState([]);
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  useEffect(() => {
    const getMovies = async () => {
      const fetchedMovies = await fetchMovies();
      setMovies(fetchedMovies);
    };

    getMovies();
  }, []);

  function LeftArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-slick-prev`}
        onClick={onClick}
        style={{
          left: "10px",
          zIndex: "1000",
          fontSize: "30px",
          color: "white",
          top: "50%",
          transform: "translateY(-50%)"
        }}
      ></div>
    );
  }
  
  function RightArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={`${className} custom-slick-next`}
        onClick={onClick}
        style={{
          right: "10px",
          zIndex: "1000",
          fontSize: "30px",
          color: "white",
          top: "50%",
          transform: "translateY(-50%)"
        }}
      ></div>
    );
  }
   

  if (isMobile) {
    return (
      <div style={{ width: "100%" }} className="carousel-custom-container">
        <Carousel>
          {movies.map((movie, index) => (
            <Carousel.Item key={index}>
              <Link to={`/movie/${movie.id}`}>
                <div className="carousel-custom-item">
                  <img
                    src={movie.thumbnail}
                    alt={movie.movie_title}
                    className="carousel-custom-item__img"
                  />
                  <div className="carousel-custom-item__title">
                    <h2>{movie.movie_title}</h2>
                  </div>
                </div>
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );
  }

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  return (
    <div style={{ width: "100%" }} className="carousel-custom-container">
      <Slider {...slickSettings}>
        {movies.map((movie, index) => (
          <Link to={`/movie/${movie.slug}`} key={index}>
            <div className="carousel-custom-item" key={index}>
              <img
                src={movie.thumbnail}
                alt={movie.movie_title}
                className="carousel-custom-item__img"
              />
              {/* <div className="carousel-custom-item__title">
                <h2>{movie.movie_title}</h2>
              </div> */}
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselCustom;
